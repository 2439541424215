import React from "react";
import { Link } from "gatsby";

const HeroCTAS = ({ cta1, cta2 }) => {
  return (
    <div className="ctas">
      {cta1 && (
        <Link to={cta1.link} className="button large primary">
          {cta1.label}
        </Link>
      )}
      {cta2 && (
        <Link to={cta2.link} className="button large white">
          {cta2.label}
        </Link>
      )}
    </div>
  );
};

export default HeroCTAS;
