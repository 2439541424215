import { graphql } from "gatsby";
import React from "react";
import Head from "../components/head";
import Layout from "../components/layout";
import BlocsContent from "../components/sections/BlocsContent";
import ContactForm from "../components/sections/ContactForm";
import ContactLink from "../components/sections/ContactLink";
import Hero from "../components/sections/Hero";
import HeroStandard from "../components/sections/HeroStandard";

export const query = graphql`
  query ($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      title
      slug
      seoTitle
      seoDescription
      image {
        gatsbyImageData(width: 2000, height: 700)
        title
      }
      mobileImage: image {
        gatsbyImageData(width: 800, height: 500)
      }
      blocs {
        ... on Node {
          ... on ContentfulBlocArticle {
            __typename
            id
            title
            textContent {
              raw
            }
            image {
              title
              gatsbyImageData(
                layout: FULL_WIDTH
                width: 600
                placeholder: DOMINANT_COLOR
              )
            }
            isImageLeft
            link
            buttonLabel
            bgColor
          }

          ... on ContentfulBlocVideo {
            __typename
            id
            title
            video_url
            bgColor
          }

          ... on ContentfulBlocGalerieDePhotos {
            __typename
            id
            title
            text {
              raw
            }
            bgColor
            images {
              title
              gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  }
`;

const Page = ({ data }) => {
  const { title, slug, seoTitle, seoDescription, image, mobileImage, blocs } =
    data.page;

  return (
    <Layout>
      <Head title={seoTitle || title} description={seoDescription} />
      {image !== null ? (
        <Hero title={title} image={image} mobileImage={mobileImage} />
      ) : (
        <HeroStandard title={title} />
      )}
      <BlocsContent blocs={blocs} />
      {slug === "contact" ? <ContactForm /> : null}
      <ContactLink />
    </Layout>
  );
};

export default Page;
