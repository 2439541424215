import { useState } from "react";

export default function useToggler(initialValue = true) {
  const [isChecked, setisChecked] = useState(initialValue);

  const toggle = function () {
    setisChecked((isChecked) => !isChecked);
  };
  return [isChecked, toggle];
}
