import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import HeroCTAS from "../atoms/HeroCTAS";

const Hero = ({ title, subtitle, image, mobileImage, cta1, cta2 }) => {
  return (
    <>
      <section className="block--hero-gradient full-width">
        <div className="show-for-large">
          <GatsbyImage image={getImage(image)} alt={image.title || title} />
        </div>
        <div className="hide-for-large">
          <GatsbyImage
            image={getImage(mobileImage)}
            alt={image.title || title}
          />
        </div>
        <div className="grid-container">
          <div className="block--hero-gradient--content text-center">
            <strong className="h1 text-white letter-spacing fw-700 text-uppercase">
              {title}
            </strong>
            {subtitle && (
              <h1 className="h2 font-primary text-white mb-0">{subtitle}</h1>
            )}
            {cta1?.label && cta1?.link && <HeroCTAS cta1={cta1} cta2={cta2} />}
          </div>
        </div>
      </section>
      <div id="next"></div>
    </>
  );
};

export default Hero;
