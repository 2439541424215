import React from "react";
import useToggler from "../hooks/useToggler";

const ContactForm = () => {
  const [isChecked, toggle] = useToggler(false);

  const data = {
    emailPlaceHolder: "Votre e-mail",
    namePlaceHolder: "Nom et prénom",
    phonePlaceHolder: "Votre numéro pour être rappelé.",
    messagePlaceHolder: "Votre message",
    acceptanceLabel: (
      <div>
        J'ai lu et accepte la{" "}
        <a href="/mentions-legales">politique de confidentialité</a> de ce site
        et d'être recontacté par Strasbourg Running Tours dans le cadre de ma
        demande de contact.
      </div>
    ),
    buttonLabel: "ENVOYER MA DEMANDE",
  };

  const buttonClass = isChecked
    ? "button centered primary"
    : "button centered primary unchecked";

  return (
    <section className="mod mod--form bg-white">
      <div className="mod--form__container">
        <h2 className="mb-2 letter-spacing underlined">Contactez-nous</h2>
        <form
          action="https://getform.io/f/4c29ebbb-3cae-4a78-a267-0cf87f4059d7"
          className="contact-form"
          id="contact-form"
          method="POST"
        >
          <input
            type="email"
            id="_replyto"
            name="_replyto"
            placeholder={data.emailPlaceHolder}
            required
          />

          <input
            type="text"
            id="name"
            name="name"
            placeholder={data.namePlaceHolder}
            required
          />

          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder={data.phonePlaceHolder}
          />

          <textarea
            id="message"
            name="message"
            placeholder={data.messagePlaceHolder}
            required
          />

          <label
            htmlFor="toggler"
            className={`mb-2 checkbox-label ${
              isChecked && "checkbox-label--checked"
            }`}
          >
            <input
              type="checkbox"
              className="contact-form__acceptance"
              checked={isChecked}
              required
              name="toggler"
              id="toggler"
              onChange={toggle}
            />
            {data.acceptanceLabel}{" "}
          </label>
          <button className={buttonClass} type="submit">
            {data.buttonLabel}
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
